/* eslint-disable no-return-assign */
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { animated } from 'react-spring';
import Patreon from 'emotion-icons/fa-brands/Patreon';
import Twitter from 'emotion-icons/fa-brands/Twitter';
import Vk from 'emotion-icons/fa-brands/Vk';
import Telegram from 'emotion-icons/fa-brands/TelegramPlane';
import { useAnimation } from '@/hooks';
import { useAppContext } from '@/context';
import Logo from '@/components/Logo';

const SideDrawer = styled(animated.div)`
  height: 100vh;
  position: fixed;
  width: ${({ open }) => (open ? '50%' : '0%')};
  @media ${({ theme }) => theme.breakpoints.prebigscreen} {
    width: ${({ open }) => (open ? '100%' : '0%')};
  }
  right: 0;
  top: 0;
  display: flex;
  z-index: 19;
`;

const MenuContainer = styled.div`
  height: calc(100vh - 6.5rem);
  width: 100%;
  position: relative;
  top: 6.5rem;
  background-color: ${({ theme }) => theme.colors.mainText};
  @media ${({ theme }) => theme.breakpoints.touch} {
    height: calc(100vh - 5.5rem);
    top: 5.5rem;
  }
  z-index: 15;
`;

const MenuInsides = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  justify-content: space-between;
  overflow-x: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const OpacityDrawer = styled.div`
  position: fixed;
  left: 0%;
  top: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.colors.blackText};
  opacity: 0.35;
  height: 100vh;
  width: 100%;
  z-index: 10;
  @media ${({ theme }) => theme.breakpoints.prebigscreen} {
    display: none;
  }
`;

const MainNavigation = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const BottomLogos = styled(animated.div)`
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  width: 100%;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    width: 50%;
  }
  justify-content: center;
  flex: 0 0 auto;
  margin: 0 auto;
  margin-bottom: 8rem;
  bottom: 0;
`;

const TopLogo = styled(animated.div)`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin: 2rem auto;
  margin-top: 1rem;
`;

const VkIcon = styled(Vk)`
  cursor: pointer;
  height: 100%;
  color: ${({ theme }) => theme.colors.main};
  opacity: 1;
  margin: 0;
  margin-left: auto;
  margin-right: 4rem;
  :focus,
  :hover,
  :active {
    opacity: 0.8;
  }
`;

const TwitterIcon = styled(Twitter)`
  cursor: pointer;
  margin: 0;
  margin-right: 4rem;
  height: 100%;
  color: ${({ theme }) => theme.colors.main};
  opacity: 1;
  :focus,
  :hover,
  :active {
    opacity: 0.8;
  }
`;
const PatreonIcon = styled(Patreon)`
  cursor: pointer;
  margin: 0;
  margin-right: 4rem;
  height: 100%;
  color: ${({ theme }) => theme.colors.main};
  opacity: 1;
  :focus,
  :hover,
  :active {
    opacity: 0.8;
  }
`;
const TelegramIcon = styled(Telegram)`
  cursor: pointer;
  margin: 0;
  margin-right: auto;
  height: 100%;
  opacity: 1;
  color: ${({ theme }) => theme.colors.main};

  :focus,
  :hover,
  :active {
    opacity: 0.8;
  }
`;

const MenuItem = styled(animated.a)`
  margin: 0 auto;
  height: 10rem;
  width: 80%;
  margin-bottom: 3rem;
  outline: none;
  display: block;
  border: none;
  font-size: 6.5rem;
  text-align: center;
  line-height: 10rem;
  text-decoration: none;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.mainText};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.mainOpacity};
    color: ${({ theme }) => theme.colors.mainText};
  }
  @media ${({ theme }) => theme.breakpoints.prebigscreen} {
    height: 8rem;
    line-height: 8rem;
    font-size: 5rem;
  }
  @media ${({ theme }) => theme.breakpoints.touch} {
    height: 6rem;
    line-height: 6rem;
    font-size: 3.5rem !important;
  }
  @media ${({ theme }) => theme.breakpoints.hd} {
    font-size: 7rem;
  }
  @media ${({ theme }) => theme.breakpoints.beforemaxwidth} {
    font-size: 6rem;
  }
`;

const MenuComponent = () => {
  const { isMenuOpen, toggleMenuOpen, menuRef } = useAppContext();
  const { mainElement, childrenElements, secondElement, thirdElement } = useAnimation(isMenuOpen);
  const goOutside = useCallback(to => {
    window.location.href = to;
  }, []);
  return (
    <>
      <SideDrawer style={mainElement} open={isMenuOpen}>
        <MenuContainer ref={menuRef}>
          <MenuInsides>
            <TopLogo style={secondElement}>
              <Logo />
            </TopLogo>
            <MainNavigation>
              {childrenElements.map(({ item, props: childrenProps, key }) => (
                <MenuItem style={childrenProps} key={key} onClick={() => toggleMenuOpen(item.to)}>
                  {item.name}
                </MenuItem>
              ))}
            </MainNavigation>
            <BottomLogos style={thirdElement}>
              <VkIcon onClick={() => goOutside('https://vk.com/cybervalhalla')} />
              <TwitterIcon onClick={() => goOutside('https://twitter.com/cybervalhalla')} />
              <PatreonIcon onClick={() => goOutside('https://patreon.com/cybervalhalla')} />
              <TelegramIcon onClick={() => goOutside('https://t.me/cybervalhalla')} />
            </BottomLogos>
          </MenuInsides>
        </MenuContainer>
      </SideDrawer>
      <OpacityDrawer open={isMenuOpen} onClick={() => toggleMenuOpen()} />
    </>
  );
};

export default React.memo(MenuComponent);
