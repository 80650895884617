import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function SEO({ description, lang, meta, keywords, title, image, date, author, subtitle, slug }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const url = `https://cvlh.io/${slug}`;
  const metaDescription = description || site.siteMetadata.description;
  const dateObject = date
    ? {
        property: 'article:published_time',
        content: date,
      }
    : {};
  const authorLink = author?.slug?.current
    ? {
        property: 'article:author_url',
        content: `https://cvlh.io/authors/${author.slug.current}`,
      }
    : {};
  const authorObject = author?.name
    ? {
        property: 'article:author',
        content: author.name,
      }
    : {};
  const descriptionObject = description
    ? {
        property: 'description:available',
        content: 'true',
      }
    : {};
  const realSubtitle = description
    ? {
        property: 'article:subtitle',
        content: description,
      }
    : {};
  const subtitleObject = subtitle
    ? {
        property: 'subtitle',
        content: subtitle,
      }
    : {};
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        dateObject,
        descriptionObject,
        authorObject,
        subtitleObject,
        authorLink,
        realSubtitle,
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:site',
          content: '@cybervalhalla',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: image,
        },
      ]
        .concat(
          keywords.length
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    >
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-159128766-1" />
      <script>
        {`
         if (true) {
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push( arguments );}
          gtag('js', new Date());
          gtag('config', 'UA-159128766-1');
          }
        `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'ru',
  meta: [],
  keywords: [],
  description: '',
  image: '',
  date: '',
  author: {},
  subtitle: '',
  slug: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.object,
  subtitle: PropTypes.string,
  slug: PropTypes.string,
};

export default SEO;
