import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ButtonToTop from '../ButtonToTop';
import Footer from '../Footer';
import Header from '../Header';
import Sidedrawer from '../Sidedrawer';

// const ButtonToTopFullScreen = MatchMediaHOC(ButtonToTop, "screen and (min-width: 1200px)")

const MainLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainContent = styled.main`
  flex: 1 0 auto;
  margin: 2rem 0;
`;

const Layout = ({ children, title, prev, next, navigation }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  const isDesktop = useMediaQuery({
    query: 'screen and (min-width: 1250px)',
  });

  return (
    <MainLayout>
      <Header siteTitle={site.siteMetadata.title} articleName={title} prev={prev} next={next} navigation={navigation} />
      <Sidedrawer />
      <MainContent>{children}</MainContent>
      <Footer siteTitle={site.siteMetadata.title} />
      {isDesktop && <ButtonToTop />}
    </MainLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  prev: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  next: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  navigation: PropTypes.array,
};
Layout.defaultProps = {
  title: '',
  prev: { slug: '', title: '' },
  next: { slug: '', title: '' },
  navigation: [],
};

export default Layout;
