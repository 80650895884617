import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';
// import Links from '../Links';

const MainFooter = styled.footer`
  /* background-color: ${({ theme }) => theme.colors.main}; */
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.mainText};
`;

// const FooterRows = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: space-between;
//   /* width: 100%; */
//   flex-wrap: wrap;
// `;

// const Row = styled.ul`
//   display: flex;
//   flex-direction: column;
//   list-style: none;
//   @media ${({ theme }) => theme.breakpoints.mobile} {
//     width: 50%;
//   }
//   width: auto;
//   padding-bottom: 1.5rem;
//   padding-right: 1rem;
// `;

// const Item = styled.li`
//   font-weight: ${({ main }) => (main ? 700 : 500)};
//   font-size: 1.15em;
//   line-height: 1.65em;
//   padding-bottom:${({ main }) => (main ? '0.2rem' : 0)};
//   /* font-size: ${({ main }) => (main ? '1.2em' : '1em')}; */
// `;

// const Link = styled(props => <Links {...props} />)`
//   text-decoration: none;
//   color: ${({ theme }) => theme.colors.mainText};
//   :hover,
//   :focus {
//     color: ${({ theme }) => theme.colors.whiteGray};
//   }
// `;

const FooterCredit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.main};
  @media ${({ theme }) => theme.breakpoints.prebigscreen} {
    padding-bottom: 2rem;
  }
`;

const Footer = ({ siteTitle }) => {
  const footerCredit = `© ${new Date().getFullYear()} ${siteTitle}`;
  return (
    <MainFooter>
      <Container>
        {/* <FooterRows>
          <Row>
            <Item main>Авторы:</Item>
            {navigation.authors.edges.map(author => (
              <Item key={author.node.name}>
                <Link to={`/author/${author.node.slug.current}`}>{author.node.name}</Link>
              </Item>
            ))}
          </Row>
          <Row>
            <Item main>Теги:</Item>
            {navigation.tags.edges.map(tag => (
              <Item key={tag.node.title}>
                <Link to={`/tag/${tag.node.address.current}`}>{tag.node.title}</Link>
              </Item>
            ))}
          </Row>
          <Row>
            <Item main>Рубрики:</Item>
            {navigation.headings.edges.map(heading => (
              <Item key={heading.node.title}>
                <Link to={`/category/${heading.node.address.current}`}>{heading.node.title}</Link>
              </Item>
            ))}
          </Row>
        </FooterRows> */}
        <FooterCredit>{footerCredit}</FooterCredit>
      </Container>
    </MainFooter>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.any.isRequired,
  // navigation: PropTypes.any.isRequired,
};

export default Footer;
