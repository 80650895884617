import { useState, useEffect } from 'react';

export const useScroll = () => {
  const [position, setPosition] = useState({
    prevScrollpos: window.pageYOffset || 0,
    visible: false,
  });
  let ticking = false;
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const fullOffset =
      document.documentElement.scrollHeight -
      document.documentElement.getElementsByTagName('footer')[0].clientHeight -
      document.body.clientHeight;
    const visible = currentScrollPos >= 300 && currentScrollPos <= fullOffset;

    setPosition({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };
  const throttleScroll = () => {
    const { prevScrollpos } = position;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleScroll(prevScrollpos);
        ticking = false;
      });

      ticking = true;
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', throttleScroll);
    return () => window.removeEventListener('scroll', throttleScroll);
  });
  return [position];
};
