import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useAppContext } from '@/context';

export default function Links(props) {
  const { className, children, ...otherProps } = props;
  const { resetNavigation } = useAppContext();
  return (
    <Link className={className} {...otherProps} onClick={() => resetNavigation()}>
      {children}
    </Link>
  );
}

Links.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Links.defaultProps = {
  className: '',
};
