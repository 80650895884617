import Brightness from 'emotion-icons/material/BrightnessMedium';
import React from 'react';
import styled from '@emotion/styled';
import { useAppContext } from '@root/src/context';

const StyledBrightness = styled(Brightness)`
  color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
`;

export default function DarkMode() {
  const { toggleDark } = useAppContext();
  return <StyledBrightness onClick={toggleDark} />;
}
