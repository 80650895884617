import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const defaultState = {
  navigation: false,
  setNavigation: () => {},
};

export const NavigationContext = createContext(defaultState);

export default function context({ children }) {
  const [navigation, setNavigation] = useState(false);
  return <NavigationContext.Provider value={{ navigation, setNavigation }}>{children}</NavigationContext.Provider>;
}

context.propTypes = {
  children: PropTypes.node.isRequired,
};
