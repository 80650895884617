import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import { useScrollPosition } from '@/hooks/useScrollPosition';

const MainHeader = styled.header`
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) => theme.colors.secondaryText};
  display: flex;
  width: 100vw;
  position: fixed;
  height: 6.5rem;
  z-index: 20;
  transition: top 0.2s ease-in;
  box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  top: ${({ hide }) => (hide ? '-6.5rem' : 0)};
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.touch} {
    height: 5.5rem;
    top: ${({ hide }) => (hide ? '-5.5rem' : 0)};
  }
`;

const Navigation = styled.nav`
  margin: 0 auto auto;
  width: 100%;
  /* max-width: ${({ theme }) => theme.sizes.maxWidth}; */
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.1rem;
  @media ${({ theme }) => theme.breakpoints.beyondmaxwidth} {
    padding: 1rem 3rem;
  }
`;

const ShadowHeader = styled.div`
  width: 100%;
  position: relative;
  height: 6.5rem;
  top: 0;
  right: 0;
  z-index: -15;
  flex: 0 0 6.5rem;
  @media ${({ theme }) => theme.breakpoints.touch} {
    flex: 0 0 5.5rem;
    height: 5.5rem;
  }
`;

const NewNavbar = ({ siteTitle, articleName, next, prev, navigation }) => {
  const [hideOnScroll, setHideOnScroll] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y <= -300 && currPos.y < prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
    undefined,
    undefined,
    1000
  );
  const isTouch = useMediaQuery({
    query: 'screen and (max-width: 767px)',
  });
  const hide = isTouch && hideOnScroll;
  return (
    <>
      <MainHeader hide={hide}>
        <Navigation>
          <Navbar siteTitle={siteTitle} articleName={articleName} next={next} prev={prev} navigation={navigation} />
          {/* <Search /> */}
        </Navigation>
      </MainHeader>
      <ShadowHeader />
    </>
  );
};

NewNavbar.propTypes = {
  siteTitle: PropTypes.string,
  articleName: PropTypes.string,
  prev: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  next: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  navigation: PropTypes.any.isRequired,
};
NewNavbar.defaultProps = {
  siteTitle: '',
  articleName: '',
  prev: { slug: '', title: '' },
  next: { slug: '', title: '' },
};

export default React.memo(NewNavbar);
