import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { useAppContext } from '@/context';

const LogoContainer = styled.div`
  @media ${({ theme }) => theme.breakpoints.touch} {
    width: 35px;
    height: 35px;
  }

  width: 45px;
  height: 45px;
  opacity: 1;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main};
  :focus,
  :hover,
  :active {
    opacity: 0.8;
  }
`;

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

export default () => {
  const { isMenuOpen, toggleMenuOpen } = useAppContext();
  const goto = useCallback(() => {
    toggleMenuOpen();
    navigate('/');
  }, [isMenuOpen]);
  return (
    <LogoContainer onClick={goto}>
      <SVG viewBox="0 0 500 500" fill="currentColor">
        <g>
          <path
            className="st0"
            d="M118.4,157.2c-3.3-1.5-6.2-3.3-8.4-5.4c-16.7-15.7,1.8-75.8,1.8-75.8C51.6,99.2,37.1,178.4,59.5,200.3
		c8.1,7.9,22.9,15.3,38.5,21.5C98.1,214.9,99.2,182.1,118.4,157.2z"
          />
          <path
            className="st0"
            d="M361.7,162.5c14.2,24.8,14.1,61.7,13.9,68.5c1.8-0.6,3.8-1.2,5.7-1.8c1-8.2,3-38.1-13.6-67.7
		C365.7,161.9,363.7,162.2,361.7,162.5z"
          />
          <path
            className="st0"
            d="M388.3,76.1c0,0,18.5,60.1,1.8,75.8c-4.1,3.8-9.9,6.4-16.4,8.2c17.5,26.9,15.6,60.5,15.1,66.6
		c19.9-7,41.3-16.2,51.7-26.4C462.9,178.4,448.4,99.2,388.3,76.1z"
          />
          <path
            className="st0"
            d="M124.1,159.4c-18.1,27.2-19.1,56-18.9,65.1c1.9,0.7,3.7,1.4,5.6,2c0.6-8.8,3.9-42.9,19.1-65.5
		C127.9,160.5,126,160,124.1,159.4z"
          />
          <path
            className="st0"
            d="M346.4,255.5c3.5,3.8,6.5,6,7.5,5.3c1.1-0.8-0.6-5.1-4-10.4c2.5,2.3,4.5,3.6,5.2,3c0.9-0.6,0.1-3.3-1.9-6.8
		c2.2,0.9,3.9,1.1,4.7,0.2c1.3-1.4-0.5-5.2-4.1-9.2c0.8-0.2,1.7-0.5,2.6-0.7c3,1.6,5.4,2.1,6.4,1c0.6-0.6,0.6-1.6,0.1-2.9
		c1.2-0.4,2.4-0.7,3.8-1.1c0.5-8.8,1.6-42.4-10.8-70.5l0.1-0.1c-4.9,0.5-9.6,0.7-13.2,0.8c-0.6-1.9-2.4-3.3-4.5-3.3
		c-0.3,0-0.6,0.1-0.8,0.1c-0.8-0.7-1.7-1.3-2.6-1.7c0.1-0.4,0.1-0.7,0.1-1.1c0-2.6-2.1-4.8-4.8-4.8c-2,0-3.7,1.2-4.4,3
		c-0.5-0.2-1-0.3-1.5-0.3c-1.3,0-2.5,0.5-3.4,1.4c-0.9-0.9-2-1.4-3.4-1.4c-2.1,0-4,1.4-4.6,3.4c-12.8-15.9-37.2-26.7-65.2-26.7
		c-27.2,0-51,10.1-64.1,25.3c-0.9-1.2-2.3-2-3.9-2c-1.3,0-2.5,0.5-3.4,1.4c-0.9-0.9-2-1.4-3.4-1.4c-0.5,0-1,0.1-1.5,0.3
		c-0.7-1.7-2.4-3-4.4-3c-2.6,0-4.8,2.1-4.8,4.8c0,0.4,0.1,0.7,0.1,1.1c-1,0.5-1.8,1-2.6,1.7c-0.3-0.1-0.5-0.1-0.8-0.1
		c-2.1,0-3.9,1.4-4.5,3.3c-5.3-0.2-12.1-0.6-18.9-1.8c-13.7,25.4-16,56.7-16.3,67.3c7,2.3,13.6,4.3,19.1,5.9c-0.2,0.9-0.1,1.5,0.4,2
		c0.8,0.9,2.7,0.7,5.2-0.5c1.1,0.3,2.2,0.6,3.2,0.9c-3.2,3.7-4.6,7.1-3.4,8.5c0.8,0.9,2.5,0.7,4.6-0.2c-1.9,3.6-2.7,6.2-1.9,6.8
		c0.8,0.6,2.7-0.6,5.2-3c-3.4,5.3-5.1,9.6-4,10.4c0.7,0.5,2.4-0.5,4.6-2.4c-9.3,19.9-21.8,48.2-21.7,56.8l4.2-5.5v17
		c4.1,3.3,4.4,7,7.3,11.1l5.7-8.2l-2.7,12.1c8.8,11.9,21.4,26,36.7,39.7l10.9-17.4l-4.1,23.3c17.5,14.6,38.3,28.1,61.1,37.2
		c45.1-17.9,81.9-53.3,102.1-79.4l-4.2-16.2l7,12.4c2.6-3.5,4.6-6.8,6.6-9.9v-21.2l7.5,5.2C369.3,306,355.9,275.5,346.4,255.5z
		 M221,261.3c0,0-23.1,0.6-34.2-6.4c0,0,6.3-9.9,21.3-8.3C223.1,248.2,221,261.3,221,261.3z M284.2,193.8l5.6,4.3v16.3h-5.6V193.8z
		 M305.5,325.2l-3.6,3.5v-12.5l3.6-0.1V325.2z M305.5,311.6H302v-21.9h3.6V311.6z M317.7,305.4l-4.1,1.7v-7.2h4.1V305.4z
		 M317.7,293.8h-4.1v-4.1h4.1V293.8z M317.7,283.6h-4.1v-8.9l-6.4-14.5l-2.1-3.2c-11.5,3.2-25.4,2.8-25.4,2.8s-1-11.2,7.3-13.4l0,0
		l-3-4.6v-21.5h5.6v19.6l4.9,5.1c14.3-1.2,19.9,8.4,19.9,8.4c-1.7,1.1-4,2-6.2,2.7l2.6,3.7l6.8,14L317.7,283.6L317.7,283.6z"
          />
        </g>
      </SVG>
    </LogoContainer>
  );
};
