import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  /* padding: 3em 1.5em 2em; */
  padding: 3rem 0 2rem;
  flex-grow: 1;
  @media ${({ theme }) => theme.breakpoints.beforemaxwidth} {
    padding: 2rem 1.5rem 0rem;
  }
`;

const PostWrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: 850px;
  /* padding: 3em 1.5em 2em; */
  padding: 3em 0 2em;
  flex-grow: 1;
  @media ${({ theme }) => theme.breakpoints.beforemaxwidth} {
    padding: 3rem 1.5rem 2rem;
  }
`;

export const PostContainer = ({ children, className }) => <PostWrapper className={className}>{children}</PostWrapper>;

const Container = ({ children, className }) => <Wrapper className={className}>{children}</Wrapper>;

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: '',
};
PostContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PostContainer.defaultProps = {
  className: '',
};

export default Container;
