import { useState, useRef } from 'react';
import { useTransition, useSpring, useChain } from 'react-spring';
import { useMediaQuery } from 'react-responsive';

export const useAnimation = open => {
  const springRef = useRef();
  const transitionRef = useRef();

  const isDesktop = useMediaQuery({
    query: 'screen and (max-width: 991px)',
  });
  const width = isDesktop ? '100%' : '50%';
  const [data] = useState([
    // {
    //   name: 'НОВОСТИ',
    //   to: '/news',
    // },
    {
      name: 'ТЕКСТЫ',
      to: '/articles',
    },
    {
      name: 'ПОДКАСТЫ',
      to: '/podcasts',
    },
  ]);

  const mainElement = useSpring({
    ref: springRef,
    width,
    to: { width: open ? width : '0%' },
  });

  const secondEl = useRef();
  const thirdEl = useRef();

  const secondElement = useSpring({
    ref: secondEl,
    opacity: open ? 1 : 0,
  });

  const thirdElement = useSpring({
    ref: thirdEl,
    opacity: open ? 1 : 0,
  });

  const childrenElements = useTransition(open ? data : [], item => item.name, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
    trail: open ? 100 : 0,
    ref: transitionRef,
  });
  useChain(open ? [springRef, secondEl, transitionRef, thirdEl] : [secondEl, transitionRef, thirdEl, springRef], [
    0,
    0,
    0,
    open ? 0.2 : 0.4,
  ]);

  return { mainElement, thirdElement, secondElement, childrenElements };
};
