import styled from '@emotion/styled';
import ArrowUp from 'emotion-icons/fa-solid/ArrowUp';
import React, { useState, useEffect } from 'react';
import { useAppContext } from '@/context';
import { useScroll } from '@/hooks';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {});
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Button = styled.a`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  background-color: ${({ theme }) => theme.colors.main};
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  width: 5rem;
  transition: opacity 1s;
  padding: 1rem;
  height: 5rem;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.mainText};
  :hover,
  :active {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.mainText};
  }
  svg {
    display: flex;
  }
  z-index: 19;
`;

export default function ButtonToTop() {
  const { isMenuOpen } = useAppContext();
  const [position] = useScroll();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    (async () => {
      if (isVisible !== position.visible) {
        if (isVisible) {
          await sleep(500);
          setIsVisible(position.visible);
        }
        setIsVisible(position.visible);
      }
    })();
  }, [position, isVisible]);

  return (
    <Button visible={!isMenuOpen && isVisible} href="#">
      <ArrowUp />
    </Button>
  );
}
