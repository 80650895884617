import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  flex-grow: 1;
  @media ${({ theme }) => theme.breakpoints.beforemaxwidth} {
    padding: 0 1.5rem;
  }
`;

const Container = ({ children, className }) => <Wrapper className={className}>{children}</Wrapper>;

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: '',
};
export default Container;
