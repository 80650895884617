import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
// import Menu from './Menu';
import TextLogo from './Logo';
import DarkMode from './DarkMode';

const NavContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-content: center;
  z-index: 20;
  flex: 0 0 auto;
  margin: 0;
  color: ${({ theme }) => theme.colors.mainText};
`;

const RightNav = styled.li`
  display: flex;
  flex-direction: flex-end;
  font-size: 1.5rem;
  text-align: center;
  align-items: center;
  width: 4.5rem;
  @media ${({ theme }) => theme.breakpoints.touch} {
    height: 3.5rem;
  }
  flex-basis: 5rem;
  justify-content: flex-end;
`;

const LeftNav = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  width: 12rem;
  flex-basis: 12rem;
  @media ${({ theme }) => theme.breakpoints.touch} {
    height: 3.5rem;
    width: 9.33rem;
  }
`;

const MiddleBlock = styled.li`
  display: flex;
  flex-direction: column;
  max-width: 105rem;
  height: 100%;
  color: black;
  padding: 0 2rem;
  flex: 1;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoints.touch} {
    display: none;
  }
`;

// const TextBlock = styled.a`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 1.5rem;
//   text-decoration: none;
// `;

const Navbar = () => {
  // const [href, text] = navigation;
  // siteTitle, articleName, next, prev,
  return (
    <NavContainer>
      <LeftNav>
        <TextLogo />
      </LeftNav>
      <MiddleBlock>{/* <TextBlock href={href}>{text}</TextBlock> */}</MiddleBlock>
      <RightNav>
        <DarkMode />
        {/* <Menu siteTitle={siteTitle} articleName={articleName} next={next} prev={prev} /> */}
      </RightNav>
    </NavContainer>
  );
};

Navbar.propTypes = {
  // siteTitle: PropTypes.string,
  // articleName: PropTypes.string,
  prev: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  next: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  // navigation: PropTypes.array,
};

Navbar.defaultProps = {
  // siteTitle: '',
  // articleName: '',
  prev: { slug: '', title: '' },
  next: { slug: '', title: '' },
  // navigation: [],
};

export default React.memo(Navbar);
